<template>
    <div class="container mx-auto text-center mt-10">
      <h1 class="text-4xl font-bold">404</h1>
      <p class="text-lg">Página no encontrada</p>
      <router-link to="/" class="text-blue-500 hover:underline">Regresar a la página principal</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  };
  </script>
  
  <style>
  /* Estilos para la página 404, si los necesitas */
  </style>
  