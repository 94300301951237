import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue'; // Importar la página principal
import BirdDetail from '../pages/BirdDetail.vue'; // Importar la página de detalles del ave
import NotFound from '../pages/NotFound.vue'; // Importar la página 404

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { title: 'Buscador de Aves' }
  },
  {
    path: '/bird/:id', // Ruta dinámica para cada ave
    name: 'BirdDetail',
    component: BirdDetail,
    meta: { title: 'Detalles del Ave' }
  },
  {
    path: '/:catchAll(.*)', // Ruta para manejar 404
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Página no encontrada' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Cambiar el título según la ruta
router.afterEach((to) => {
  document.title = to.meta.title || 'Aves - Loro Parque';
});

export default router;
