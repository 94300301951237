<template>
    <div id="app" class="font-roboto bg-gray-100">
        <div class="flex flex-col align-center justify-center">
            <search-bar @search="searchBirds" class="mt-4 mx-4 lg:px-96"></search-bar>
            <div class="px-6 py-2">
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:min-h-[68vh] lg:max-h-[68vh]">
                    <div class="mt-6 w-full" v-for="bird in showBirds" :key="bird.id">
                        <birdCard :bird="bird" class="w-full"></birdCard>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8 mb-4 flex justify-center">
            <Pagination @update:currentPage="changePage" :totalItems="number_of_birds" :itemsPerPage="BIRDS_PER_PAGE">
            </Pagination>
        </div>
    </div>
</template>

<script setup>
import birdCard from "../components/birdCard";
import Pagination from "../components/elements-pagination";
import SearchBar from "../components/searchBar";
import Birds from "../data/birds.json";
import { onBeforeMount } from "vue";

// Ordenar las aves por nombre científico
Birds.sort((a, b) => {
    if (a.scientific_name < b.scientific_name) return -1;
    if (a.scientific_name > b.scientific_name) return 1;
    return 0;
});

import { ref } from "vue";

const BIRDS_PER_PAGE = 4;
let allBirds = ref(Birds);
let showBirds = ref(Birds.slice(0, BIRDS_PER_PAGE));
let number_of_birds = ref(Birds.length);

const changePage = (page) => {
    const start = (page - 1) * BIRDS_PER_PAGE;
    const end = start + BIRDS_PER_PAGE;
    console.log(start, end);
    showBirds.value = allBirds.value.slice(start, end);
    console.log(number_of_birds.value);
};

const searchBirds = (searchQuery) => {
    if (!searchQuery) {
        allBirds.value = Birds;
    } else {
        allBirds.value = Birds.filter((bird) => {
        if (!bird.common_name) bird.common_name = '';
        if (!bird.scientific_name) bird.scientific_name = '';

        return bird.common_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            bird.scientific_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    }
    number_of_birds.value = allBirds.value.length;
    changePage(1);
};

// Ejecutar la función antes de que el componente se monte
onBeforeMount(() => {
    changePage(1);
});
</script>

<style>
@import "../assets/css/app.css";

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #2D3748;
}

::-webkit-scrollbar-thumb {
    background: #CBD5E0;
}

::-webkit-scrollbar-thumb:hover {
    background: #2D3748;
}
</style>
