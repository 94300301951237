<template>
    <ul class="flex">
        <!-- Botón para ir a la página anterior -->
        <li 
            @click.prevent="goToPreviousPage"
            class="mx-1 px-3 py-2 bg-gray-200 text-gray-500 rounded-lg"
            :class="{ 'opacity-50 cursor-not-allowed hover:bg-gray-200 pointer-events-none': currentPage === 1, 'hover:bg-gray-700 hover:text-gray-200 cursor-pointer': currentPage !== 1,
            hidden: totalPages <= 1 }"
        >
            <a 
                class="flex items-center font-bold" 
                href="#" 
            >
                Anterior
            </a>
        </li>

        <!-- Rango de páginas visible -->
        <li 
            @click.prevent="goToPage(page)"
            v-for="page in pageRange" 
            :key="page" 
            class="mx-1 px-3 py-2 hover:bg-gray-700 hover:text-gray-200 rounded-lg cursor-pointer"
            :class="{ 'text-gray-200 bg-gray-700': page === currentPage,
            'bg-gray-200 text-gray-700' : page !== currentPage }"

        >
            <a class="font-bold" href="#">{{ page }}</a>
        </li>

        <!-- Botón para ir a la página siguiente -->
        <li 
            @click.prevent="goToNextPage"
            class="mx-1 px-3 py-2 bg-gray-200 text-gray-700 rounded-lg"
            :class="{ 'opacity-50 cursor-not-allowed hover:bg-gray-200 pointer-events-none': currentPage === totalPages, 'hover:bg-gray-700 hover:text-gray-200 cursor-pointer': currentPage !== totalPages,
            hidden: totalPages <= 1 }"
        >
            <a 
                class="flex items-center font-bold" 
                href="#" 
            >
                Siguiente
            </a>
        </li>
    </ul>
</template>

<script setup>
import { ref, computed } from 'vue';
import { defineProps } from 'vue';
import { defineEmits } from 'vue';

// Definir los eventos
const emits = defineEmits(['update:currentPage']);

// Definir las props
const props = defineProps({
    totalItems: {
        type: Number,
        required: true
    },
    itemsPerPage: {
        type: Number,
        required: true
    }
});

// Variables reactivas para el estado de la página
let currentPage = ref(1);
let maxPagesDisplay = 3;

// Calcular el número total de páginas
const totalPages = computed(() => Math.ceil(props.totalItems / props.itemsPerPage));

// Calcular el rango de páginas visible basado en la página actual
const pageRange = computed(() => {
    const start = Math.max(1, currentPage.value - Math.floor(maxPagesDisplay / 2));  // Desde la página actual menos 2
    const end = Math.min(totalPages.value, start + maxPagesDisplay - 1); // Hasta la página actual más 2 o hasta la última página
    
    // Si estamos al principio, asegurarnos de mostrar las primeras páginas
    const adjustedStart = Math.max(1, end - maxPagesDisplay + 1);

    return Array.from({ length: end - adjustedStart + 1 }, (_, i) => adjustedStart + i);
});

// Funciones para manejar la navegación entre páginas
const goToPage = (page) => {
    if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        emits('update:currentPage', page);
    }
};

const goToPreviousPage = () => {
    if (currentPage.value > 1) {
        goToPage(currentPage.value - 1);
    }
};

const goToNextPage = () => {
    if (currentPage.value < totalPages.value) {
        goToPage(currentPage.value + 1);
    }
};
</script>
