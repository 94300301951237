<template>
    <div ref="top" id="app" class="font-roboto bg-gray-100 flex flex-col align-center justify-between min-h-screen">
        <navbar></navbar>
        <router-view @scroll:top="focusTop" class="flex-grow"></router-view> <!-- Renderiza las rutas -->
        <simple-footer class="mt-2"></simple-footer>
    </div>
</template>

<script>
    import Navbar from "./components/navigation-navbar-simple";
    import SimpleFooter from "./components/navigation-footer-simple-with-icon";

    export default {
        name: 'app',
        components: {
            Navbar,
            SimpleFooter
        },
        methods: {
            focusTop() {
              this.$refs.top.scrollIntoView({ behavior: 'smooth' });        
            }
        }
    }
</script>

<style>
   
</style>
