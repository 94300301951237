<template>
    <div
        class="px-10 py-6 bg-white rounded-lg shadow-md flex flex-col md:flex-row md:items-center gap-4 justify-between">
        <div class="mt-2 text-left">
            <h1 class="mt-2 text-3xl text-gray-700 font-bold hover:underline">{{ bird.scientific_name }}</h1>
            <h3 class="mt-2 text-xl text-gray-700 font-bold hover:underline">{{ bird.common_name }}</h3>
            <p class="mt-2 text-gray-600">Peso: {{ bird.weight }}</p>
            <p class="mt-2 text-gray-600">Tamaño: {{ bird.size }}</p>
            <p class="mt-2 text-gray-600">Tamaño de bandada: {{ bird.flock_size }}</p>
        </div>
        <div class="flex justify-center items-center">
            <img class="object-cover max-w-60 h-60 border-2 border-black shadow-lg" :src="bird.photo_url"
                alt="Foto de {{ bird.common_name }}" v-if="bird.photo_url" />
            <img class="object-cover max-w-60 h-60" src="nophoto.png" alt="Foto no disponible" v-else>
        </div>

    </div>
</template>

<script>
export default {
    props: ['bird']
}
</script>
